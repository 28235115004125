import getConfig from 'next/config';
import React from 'react';
const { publicRuntimeConfig } = getConfig();

function EnvironmentStamp() {
  const environment_name = publicRuntimeConfig.ENVIRONMENT_NAME;
  if (!environment_name) {
    return null;
  }
  return (
    <div
      className={`stamp is-${environment_name}`.toLowerCase()}
      style={{
        zIndex: 2,
        userSelect: 'none',
        alignContent: 'center',
        marginLeft: '20%'
      }}
    >
      {environment_name}
    </div>
  );
}

export default EnvironmentStamp;

export default {
  siteName: '',
  siteIcon: 'ion-flash',
  footerText: `WEVR - v${
    process.env.NEXT_PUBLIC_VERSION
  } © ${new Date().getFullYear()} BLOX, LLC`,
  enableAnimatedRoute: false,
  apiUrl: 'http://yoursite.com/api/',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1'
  },
  segment: {
    key: process.env.SEGMENT_ID || process.env.NEXT_PUBLIC_SEGMENT_ID
  },
  dashboard: '/dashboard'
};

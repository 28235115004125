import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import PageHeader from '@iso/components/utility/pageHeader';
import React from 'react';


export default function ContentBoxWrapper({ header, children, cardLayout }) {
  return (
    <LayoutWrapper style={{ paddingTop: '1.2em' }}>
      {header && <PageHeader>{header}</PageHeader>}
      {cardLayout ? (
        <Box>
          <ContentHolder>{children}</ContentHolder>
        </Box>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      )}
    </LayoutWrapper>
  );
}

/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable @next/next/no-html-link-for-pages */
import Popover from '@iso/components/uielements/popover';
import { GlobalContext } from 'lib/context/GlobalContext';
import Image from 'next/image';
import Link from 'next/link';
import { useContext, useState } from 'react';
import TopbarDropdownWrapper from './TopbarDropdown.styles';

export default function TopbarUser() {
  const [visible, setVisibility] = useState(false);
  const { isAdmin, isTagManager, user } = useContext(GlobalContext);

  function handleVisibleChange() {
    setVisibility((visible) => !visible);
  }

  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      {isAdmin && (
        <Link href="/dashboard/admin" className="isoDropdownLink">
          Admin
        </Link>
      )}
      {isTagManager && (
        <Link href={`/dashboard/admin/tags`} className="isoDropdownLink">
          Manage Tags
        </Link>
      )}
      <Link href="/api/auth/logout" className="isoDropdownLink">
        Logout
      </Link>
    </TopbarDropdownWrapper>
  );

  if (!user) {
    return null;
  }

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <div className="isoImgWrapper">
        <Image alt="user" src={user.picture} width={40} height={40} />
        <span className="userActivity online" />
      </div>
    </Popover>
  );
}

import Menu from '@iso/components/uielements/menu';
import Logo from '@iso/components/utility/Logo.next';
import appActions from '@iso/redux/app/actions';
import { Layout } from 'antd';
import { GlobalContext } from 'lib/context/GlobalContext';
import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import EnvironmentStamp from './EnvironmentStamp';
import SIDEBAR_MENU_OPTIONS from './sidebar.navigations';
import SidebarWrapper from './Sidebar.styles';
import SidebarMenu from './SidebarMenu';

const { Sider } = Layout;
const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;

export default function Sidebar(props) {
  const { hasPermission, hasRole } = useContext(GlobalContext);

  let menuOptions = props.menuOptions || SIDEBAR_MENU_OPTIONS;
  const { view, openKeys, collapsed, openDrawer, height, current } = useSelector(
    (state) => state.App
  );
  const { sidebarTheme } = useSelector((state) => state.ThemeSwitcher);
  const dispatch = useDispatch();
  function handleClick(e) {
    dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
      }, 100);
    }
  }
  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find((key) => !(openKeys.indexOf(key) > -1));
    const latestCloseKey = openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }
  const getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2']
    };
    return map[key] || [];
  };

  const filterMenuOptions = (options) => {
    return options.filter((o) => {
      const { permission, role } = o;
      if (!permission && !role) return true;
      //check permission first as it is most restrictive
      let retVal = false;
      const hasP = permission ? hasPermission(permission) : true;
      const hasR = !role ? false : hasRole(role);
      retVal = hasR || hasP;
      return retVal;
    });
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const scrollheight = height;
  const styling = {
    backgroundColor: sidebarTheme.backgroundColor
  };
  const submenuStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: sidebarTheme.textColor
  };
  const submenuColor = {
    color: sidebarTheme.textColor
  };
  const onMouseEnter = () => {
    if (collapsed && openDrawer === false) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  const onMouseLeave = () => {
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };
  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={isCollapsed}
        width={240}
        className="isomorphicSidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={styling}
      >
        <Logo collapsed={isCollapsed} />
        <Scrollbars style={{ height: scrollheight - 70 }}>
          <Menu
            onClick={handleClick}
            theme="dark"
            mode={mode}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={current}
            onOpenChange={onOpenChange}
            className="isoDashboardMenu"
          >
            {filterMenuOptions(menuOptions).map((option) => (
              <SidebarMenu
                key={option.key}
                item={option}
                submenuColor={submenuColor}
                hasPermission={hasPermission}
                filterMenuOptions={filterMenuOptions}
                submenuStyle={submenuStyle}
              />
            ))}
          </Menu>
          <EnvironmentStamp />
        </Scrollbars>
      </Sider>
    </SidebarWrapper>
  );
}

import Breadcrumb from '@iso/components/uielements/breadcrumb';
import siteConfig from '@iso/config/site.config.next';
import { IBreadcrumb, IMenuOption } from '@wevr/common';
import { Layout, Skeleton } from 'antd';
import { Component, FC, PropsWithChildren } from 'react';
import ContentBoxWrapper from '../ContentBoxWrapper';
import Sidebar from '../Sidebar/Sidebar';
// import ThemeSwitcher from '@iso/containers/ThemeSwitcher/ThemeSwitcher';
import Topbar from '../Topbar/Topbar';
import AppHolder from './DashboardLayout.styles';
const { Content, Footer } = Layout;

interface IDashboardProps extends PropsWithChildren<unknown> {
  menuOptions?: IMenuOption[];
  loading?: boolean;
  breadcrumbs?: IBreadcrumb[];
  header?: Component | string;
  cardLayout?: boolean;
  requiredRole?: string | null;
  requiredPermission?: string | null;
}

const DashboardLayout: FC<IDashboardProps> = ({
  menuOptions,
  loading,
  breadcrumbs,
  header,
  cardLayout = false,
  children,
  requiredPermission = null,
  requiredRole = null
}) => {
  return (
    <AppHolder>
      <Layout style={{ height: '100vh' }}>
        <Topbar />

        <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
          <Sidebar menuOptions={menuOptions} />
          <Layout
            className="isoContentMainLayout"
            style={{
              height: '100vh'
            }}
          >
            <Content
              className="isomorphicContent"
              style={{
                padding: '70px 0 0',
                flexShrink: '0',
                background: '#f1f3f6',
                width: '100%'
              }}
            >
              {breadcrumbs && (
                <div style={{ marginLeft: '1.5em', marginTop: '.7em' }}>
                  <Breadcrumb separator=">">
                    {breadcrumbs.map((b) => (
                      <Breadcrumb.Item key={b.label} href={b.href || null}>
                        {b.label}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </div>
              )}

              <ContentBoxWrapper cardLayout={cardLayout} header={header}>
                {loading ? <Skeleton active={true} /> : children}
              </ContentBoxWrapper>
            </Content>
            <Footer
              style={{
                background: '#ffffff',
                textAlign: 'center',
                borderTop: '1px solid #ededed'
              }}
            >
              {siteConfig.footerText}
            </Footer>
          </Layout>
        </Layout>
        {/* <ThemeSwitcher /> */}
      </Layout>
    </AppHolder>
  );
};

export default DashboardLayout;

import siteConfig from '@iso/config/site.config.next';
import Link from 'next/link';
import React from 'react';

export default function LogoNext({ collapsed }) {
  return (
    <div className="isoLogoWrapper">
      <h3>
        <Link href="/dashboard">
          <div className={collapsed ? 'first-letter' : 'full-name'} >
            {siteConfig.siteName}
          </div>
        </Link>
      </h3>
    </div>
  );
}

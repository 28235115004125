import appActions from '@iso/redux/app/actions';
import Menu from '@iso/ui/Antd/Menu/Menu';
import { PERMISSIONS } from '@wevr/common';
import { Layout } from 'antd';
import { GlobalContext } from 'lib/context/GlobalContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Component, useContext } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import TopbarWrapper from './Topbar.styles';
import TopbarUser from './TopbarUser';

const { Header } = Layout;
const { toggleCollapsed } = appActions;

function TourButton() {
  const router = useRouter();
  const { pathsWithTours, setIsTourOpen } = useContext(GlobalContext);

  // const pageHasTours = pathsWithTours.includes(router.pathname);

  const openTour = () => {
    if (pathsWithTours.includes(router.pathname)) {
      setIsTourOpen(true);
    }
  };

  if (!pathsWithTours.includes(router.pathname)) {
    return null;
  }
  return (
    <li onClick={openTour}>
      <AiOutlineQuestionCircle style={{ fontSize: '2em', color: '#52c41a' }} />
    </li>
  );
}

function MenuItems({ styling }) {
  const { hasPermission } = useContext(GlobalContext);
  const router = useRouter();
  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={['2']}
      style={{
        ...styling,
        textTransform: 'uppercase',
        marginLeft: '24px',
        paddingTop: '2px'
      }}
    >
      {hasPermission(PERMISSIONS.CAN_VIEW_PARTS) && (
        <Menu.Item
          key="parts"
          className={
            router.pathname.indexOf('/dashboard/parts') === 0
              ? 'ant-menu-item-selected parts-menu-item'
              : 'parts-menu-item'
          }
        >
          <Link href="/dashboard/parts">Parts</Link>
        </Menu.Item>
      )}
      {hasPermission([PERMISSIONS.CAN_EDIT_BOMS, PERMISSIONS.CAN_ACCESS_DEV]) && (
        <>
          <Menu.Item
            key="projects"
            className={
              router.pathname.indexOf('/dashboard/projects') === 0 ? 'ant-menu-item-selected' : null
            }
          >
            <Link href="/dashboard/projects">Projects</Link>
          </Menu.Item>
          <Menu.Item
            key="products"
            className={
              router.pathname.indexOf('/dashboard/products') === 0 ? 'ant-menu-item-selected' : null
            }
          >
            <Link href="/dashboard/products">Products</Link>
          </Menu.Item>
          <Menu.Item
            key="units"
            className={
              router.pathname.indexOf('/dashboard/units') === 0 ? 'ant-menu-item-selected' : null
            }
          >
            <Link href="/dashboard/units">Units</Link>
          </Menu.Item>
        </>
      )}

      {hasPermission(PERMISSIONS.CAN_VIEW_BOMS) && (
        <Menu.Item
          key="bom"
          className={
            router.pathname.indexOf('/dashboard/boms') === 0 ? 'ant-menu-item-selected' : null
          }
        >
          <Link href="/dashboard/boms">BOM</Link>
        </Menu.Item>
      )}
      {hasPermission([PERMISSIONS.CAN_EDIT_BOMS, PERMISSIONS.CAN_ACCESS_DEV]) && (
        <Menu.Item
          key="boms3"
          className={
            router.pathname.indexOf('/dashboard/boms') === 0 ? 'ant-menu-item-selected' : null
          }
        >
          <Link href="/dashboard/boms3">BOM-MVP</Link>
        </Menu.Item>
      )}
      {hasPermission(PERMISSIONS.CAN_EDIT_MANUFACTURERS) && (
        <Menu.Item
          key="manufacturers"
          className={
            router.pathname.indexOf('/dashboard/manufacturers') === 0
              ? 'ant-menu-item-selected'
              : null
          }
        >
          <Link href="/dashboard/manufacturers">MANUFACTURERS</Link>
        </Menu.Item>
      )}
      {hasPermission(PERMISSIONS.CAN_EDIT_SUPPLIERS) && (
        <Menu.Item
          key="suppliers"
          className={
            router.pathname.indexOf('/dashboard/suppliers') === 0 ? 'ant-menu-item-selected' : null
          }
        >
          <Link href="/dashboard/suppliers">SUPPLIERS</Link>
        </Menu.Item>
      )}
      {hasPermission(PERMISSIONS.CAN_ACCESS_DEV) && (
        <Menu.Item key="analytics">
          <Link href="/dashboard/analytics">Analytics</Link>
        </Menu.Item>
      )}
    </Menu>
  );
}

class Topbar extends Component {
  render() {
    const { toggleCollapsed, customizedTheme, locale } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 70
    };
    return (
      <TopbarWrapper>
        <Header
          style={{ ...styling, verticalAlign: 'middle' }}
          className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}
        >
          <div className="isoLeft">
            <button
              className={collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'}
              style={{ color: customizedTheme.textColor }}
              onClick={toggleCollapsed}
            />
          </div>
          <MenuItems styling={styling} />
          <ul className="isoRight">
            <TourButton />
            <li onClick={() => this.setState({ selectedItem: 'user' })} className="isoUser">
              <TopbarUser locale={locale} />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme
  }),
  { toggleCollapsed }
)(Topbar);

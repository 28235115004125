import Menu from '@iso/components/uielements/menu';
import IntlMessages from '@iso/components/utility/intlMessages';
import siteConfig from '@iso/config/site.config.next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

const SubMenu = Menu.SubMenu;

export default function SidebarMenu({
  item,
  submenuStyle,
  submenuColor,
  hasPermission,
  filterMenuOptions,
  ...rest
}) {
  const router = useRouter();
  const { key, label, leftIcon, children } = item;
  const isElement = React.isValidElement(leftIcon);

  const url = siteConfig.dashboard;

  if (children) {
    return (
      <SubMenu
        key={key}
        className={`${key.replace('/', '-')}-sidebar-item`}
        title={
          <span className={`isoMenuHolder`} style={submenuColor}>
            {isElement && <i>{leftIcon}</i>}
            {!isElement && <i className={leftIcon} />}
            <span className="nav-text">
              <IntlMessages id={label} />
            </span>
          </span>
        }
        {...rest}
      >
        {filterMenuOptions(children).map(({ key, label, withoutDashboard }) => {
          const linkTo = withoutDashboard ? `/${key}` : `${url}/${key}`;
          return (
            <Menu.Item
              style={submenuStyle}
              key={key}
              className={router.pathname === linkTo ? 'ant-menu-item-selected' : null}
            >
              <Link href={linkTo}>
                <div className="isoMenuHolder" style={submenuColor}>
                  <span className="nav-text">
                    <IntlMessages id={label} />
                  </span>
                </div>
              </Link>
            </Menu.Item>
          );
        })}
      </SubMenu>
    );
  }
  const linkTo = `${url}/${key}`;
  return (
    <Menu.Item
      key={key}
      className={
        router.pathname === linkTo
          ? `ant-menu-item-selected ${key.replace('/', '-')}-sidebar-item`
          : `${key}-sidebar-item`
      }
      {...rest}
    >
      <Link href={linkTo}>
        <div style={submenuColor} className="isoMenuHolder">
          {isElement && <i>{leftIcon}</i>}
          {!isElement && <i className={leftIcon} />}

          <span className="nav-text">
            <IntlMessages id={label} />
          </span>
        </div>
      </Link>
    </Menu.Item>
  );
}

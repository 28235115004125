import getConfig from 'next/config';
import defaultSiteConfig from './site.config';
const { publicRuntimeConfig } = getConfig();

export default {
  ...defaultSiteConfig,
  footerText: `WEVR - v${
    publicRuntimeConfig.VERSION
  } © ${new Date().getFullYear()} BLOX, LLC`
};
